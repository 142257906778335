//-------------Importar librerias----------------------------
import React, { useState } from "react";

// -----------Importar Componentes de Terceros------------------
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import AlertMui from "../../../components/Alert_Mui";

// -----------Importar Archivos Estaticos------------------
import Agregar_Pasiva from '../../../assets/Imagenes/Nueva_Pasivas.png'
import Agregar_Activa from '../../../assets/Imagenes/Nueva_Activa.png'


const StepConmemoracionPrimero = ({ Action, OnChange, FormActualizar }) => {
    const [showAlert, setShowAlert] = useState(false);
    const [isHovered, setIsHovered] = useState(false);


    const handleMouseEnter = () => {
        setIsHovered(true);

    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    }



    const handlePicture = (e) =>
        {
            OnChange(e,()=>(setShowAlert(true)));
        }


    console.log(FormActualizar.picture);
    return (
        <Box sx={{ flexGrow: 1 }}>

            {
                showAlert === false
                    ?
                    <></>
                    :
                    <Box sx={{ marginBottom: '20px' }}>
                        <AlertMui Text='La imagen se a cargado con exito.' OnChange={() => setShowAlert(false)} />
                    </Box>

            }

            <Grid sx={{ display: 'flex', justifyContent: 'center' }}
                container

                columns={{ xs: 4, sm: 8, md: 12 }}
            >
                <Grid xs={4} sm={3} sx={{ paddingRight: { xs: '0%', sm: '2%' }, paddingLeft: '0%', display: 'flex', justifyContent: 'center' }}>
                    <Box mt={{xs:'15px', sm:'0px'}} mb={{xs:'15px', sm:'0px'}} component="label" sx={{ cursor: 'pointer', width: { xs: '100%', sm: '230px' }, height: '320px', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <input onChange={handlePicture} accept="image/png, image/jpeg, image/jpg" style={{ display: 'none' }} name="picture" type="file" alt="Submit" />

                        <div>
                            {
                                FormActualizar.picture === ''
                                ?
                                <img
                                src={ Agregar_Pasiva}
                                alt="agregar imagen"
                                style={{ width: '100%'}}
                            />
                                :
                                <img 
                                src={isHovered ?Agregar_Pasiva  : URL.createObjectURL(FormActualizar.picture)}
                                alt="agregar imagen"
                                style={{aspectRatio:'3/4', width: '230px', height: '100%', objectFit: 'cover', borderRadius: '10px' }}
                            />
                            }
                            
                        </div>
                    </Box>
                </Grid>
                <Grid xs={9} >
                    <Box display='flex' alignItems='center' flexDirection='column' >
                        <Box sx={{ padding: { xs: '3%', sm: '3% 20% 3% 2%' }, borderRadius: '20px' }} >
                            <Typography color='#D14081' align="justify" fontSize='20px' fontFamily='Itaca ' display='inline' >Selecciona una fotografía de tu galería</Typography><Typography align="justify" fontSize='20px' fontFamily='Itaca ' display='inline'  >, te sugerimos una donde solo aparezca tu ser querido.</Typography>
                        </Box>

                        <Box sx={{ padding: '3%', borderRadius: '20px', backgroundColor: '#F0F0F0' }}>
                            <ul className="conmemoracion">
                                <li>
                                    <Typography color='#3A3A3A' fontSize='18px' fontFamily='Itaca ' >
                                        Si omites este paso, podrás subir una foto más adelante.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography color='#3A3A3A' fontSize='18px' fontFamily='Itaca '  >
                                        Si necesitas ayuda con la edición de la página de conmemoración, comunícate con nosotros al 000-000-0000.
                                    </Typography >
                                </li>
                                <li>
                                    <Typography color='#3A3A3A' fontSize='18px' fontFamily='Itaca ' >
                                        Mantén a la mano tu número de folio.
                                    </Typography>
                                </li>
                            </ul>
                        </Box>

                        <Box sx={{ padding: { xs: '2% 0px 0px 0px', md: '2% 8px px 8px' }, width: '100%' }}>
                            {Action}
                        </Box>
                    </Box>
                </Grid>

            </Grid>
        </Box>
    );
}



export default StepConmemoracionPrimero
