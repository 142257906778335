// Importación de librerías y componentes necesarios para el componente Seguimiento
import React, { Component } from "react";
import axios from "axios";
import withRouter from "../../routes/withRouter"; // Importa el componente withRouter de React Router para acceder a las props de enrutamiento
import styled from "styled-components"; // Importa styled-components para aplicar estilos al componente
import TodoDataService from '../../hooks/services'; // Importa el servicio TodoDataService

// Importación de componentes de terceros
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

// Importación de componentes personalizados
import ModalBaseMui from "../../components/Modal_Mui";
import SeguimientoDetalle from "./Seguimiento_Detalle";
import AlertMui from "../../components/Alert_Mui";

// Importación de archivos estáticos
import './css/style.css'; // Importa el archivo de estilos CSS
import logoimagen from '../../assets/Imagenes/columba_logo.png'
import ImagenFolio from "../../assets/Imagenes/Seguimiento/folio_col.png"; // Importa una imagen

// URL de los servicios API
const UrlSeguimiento = 'https://preprodcolumba.prophet.mx/api/pedidocliente/seguimiento/';
const UrlConmemoracion = 'https://preprodcolumba.prophet.mx/api/conmemoraciones/';
const UrlOperaciones = 'https://preprodcolumba.prophet.mx/api/PedidoCliente/seguimiento/tareas/';
const datalogin = { email: "apicolumba@funeralescolumba.com.mx", password: "5M#j7^hBRXY#5Y!m" };
const UrlToken = 'https://preprodcolumba.prophet.mx/api/usuario/login/';
const UrlCaratula = 'https://preprodcolumba.prophet.mx/api/conmemoraciones/caratula/';

class Seguimiento extends Component {
  // Estado inicial del componente
  state = {
    Token: '', // Token de autenticación
    ModalFolio: false, // Estado del modal para ingresar el folio
    ListOperaciones: [], // Lista de operaciones
    ModalCompartir: false, // Estado del modal para compartir
    FormSeguimiento: '', // Datos del seguimiento
    FormConmemoracion: '', // Datos de la conmemoración
    FormCaratula: { Imagen: '', Extencion: '', Nacimiento: '', Defuncion: '' }, // Datos de la carátula
    Conmemoracion: false, // Estado de la conmemoración
    form: { // Datos del formulario
      Folio: '', // Número de folio
      ApPaterno: '', // Apellido paterno
    },
    Error: '', // Mensaje de error
  }

  // Método para manejar el retorno a la página inicial
  OnClickReturn = () => {
    this.setState({
      Origen: 'Principal',
      ModalFolio: false,
      ListOperaciones: [],
      ModalCompartir: false,
      FormSeguimiento: '',
      FormConmemoracion: '',
      FormCaratula: { Imagen: '', Nacimiento: '', Defuncion: '' },
      Conmemoracion: false,
      form: {
        Folio: '',
        ApPaterno: '',
      },
      Error: '',
    });
  }

  // }

  // Método para obtener el token de autenticación
  GetPeticionToken = async () => {
    let result = '';
    await axios.post(UrlToken, datalogin)
      .then(response => {
        this.setState({ Token: 'Bearer ' + response.data.token });
        result = 'Bearer ' + response.data.token;
      })
      .catch(error => {
        console.log(error);
      });
    return result;
  }

  // Método para obtener la conmemoración
  PeticionConmemoracionGet = async (item) => {
    let Folio = this.state.form.Folio !== '' ? this.state.form.Folio : item;
    axios.defaults.headers.common["Authorization"] = this.state.Token;
    await axios.request(UrlConmemoracion + Folio + '/144')
      .then(response => {
        this.setState({ FormConmemoracion: response.data });
      })
      .catch(error => {
        console.log(error.message);
      });
  }

  ConvertBase64ToBlob = async (Extencion, Imagen) => {
    const blob = await fetch(Extencion + ',' + Imagen);
    let imagen = URL.createObjectURL(await blob.blob());
    console.log(imagen);
    return imagen;
  }

  // Método para obtener la carátula
  PeticionCaratulaGet = async (item) => {
    try {
      const Folio = this.state.form.Folio !== '' ? this.state.form.Folio : item;
      axios.defaults.headers.common["Authorization"] = this.state.Token;
      const response = await axios.get(UrlCaratula + Folio);

      const { extencionFoto, foto, agnoNacimiento, agnoDefuncion } = response.data;
      const imagen = await this.ConvertBase64ToBlob(extencionFoto, foto);

      this.setState({
        FormCaratula: {
          Imagen: extencionFoto !== null ? imagen : '',
          Nacimiento: agnoNacimiento,
          Defuncion: agnoDefuncion
        }
      });

      return extencionFoto !== null ? imagen : '';
    } catch (error) {
      console.error('Error al obtener la carátula:', error.message);
      // Aquí podrías agregar lógica para manejar el error, como mostrar un mensaje al usuario.
    }
  }

  // Método para obtener las operaciones
  PeticionOperacionGet = async (item) => {
    let Folio = this.state.form.Folio !== '' ? this.state.form.Folio : item;
    axios.defaults.headers.common["Authorization"] = this.state.Token;
    await axios.request(UrlOperaciones + Folio)
      .then(response => {
        this.setState({ ListOperaciones: response.data.map(item => ({ Label: item.tarea, id: item.operacion })) });
      })
      .catch(error => {
        this.setState({ Error: error.response.data.errores.mensaje });
        console.log(error.message);
      });
  }

  // Método para obtener el seguimiento
  PeticionSeguimientoGet = async () => {
    axios.defaults.headers.common["Authorization"] = this.state.Token;
    await axios.request(UrlSeguimiento + this.state.form.ApPaterno + '/' + this.state.form.Folio)
      .then(response => {
        this.setState({ FormSeguimiento: response.data });
        this.PeticionConmemoracionGet();
        this.PeticionCaratulaGet();
        this.PeticionOperacionGet();
      })
      .catch(error => {
        console.log(error.message);
        this.setState({ Error: error.response.data.errores.mensaje });
      });
  }

  // Método para obtener el seguimiento al compartir
  PeticionSeguimientoCompartirGet = async (Folio, ApPaterno) => {
    try {
      // Configurar la cabecera de autorización
      axios.defaults.headers.common["Authorization"] = await this.GetPeticionToken();

      // Realizar la petición a la URL de seguimiento
      const response = await axios.request(UrlSeguimiento + ApPaterno + '/' + Folio);

      // Actualizar el estado y realizar otras operaciones necesarias
      this.setState({ FormSeguimiento: response.data, form: { Folio: Folio, ApPaterno: ApPaterno } });
      this.PeticionConmemoracionGet(Folio);
      const result = await this.PeticionCaratulaGet(Folio);
      this.PeticionOperacionGet(Folio);

      console.log(result);
      return result;
    } catch (error) {
      console.log(error.message);
      this.setState({ Error: error.response.data.errores.mensaje });
      // Devolver un valor predeterminado en caso de error
      return '';
    }
  }

  // Método para mostrar el modal del folio
  ShowModalFolio = () => {
    this.setState({ ModalFolio: !this.state.ModalFolio });
  }

  // Método para mostrar el modal de compartir
  ShowModalCompartir = () => {
    this.setState({ ModalCompartir: !this.state.ModalCompartir });
  }

  // Método para mostrar el seguimiento
  ShowSeguimiento = () => {
    this.PeticionSeguimientoGet();
  }

  // Maneja el cambio de los inputs del formulario
  handleChange = async e => {
    e.persist();
    await this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
    console.log(this.state.form);
  }

  // Método para compartir el seguimiento
  CompartirSeguimiento = (item) => {
    window.open(item, "_blank");
  }


  async componentDidMount() {
    this.GetPeticionToken(); // Obtiene el token de autenticación
    // Verifica si se recibieron parámetros de folio y apellido paterno
    if (this.props.params.folio && this.props.params.appaterno) {
      try {
        const imagen = await this.PeticionSeguimientoCompartirGet(this.props.params.folio, this.props.params.appaterno);
        this.setState({ Origen: 'Detalle' });
        if (imagen !== '') {
          console.log(imagen)
          fetch(imagen)
            .then(response => response.blob())
            .then(blob => {
              const reader = new FileReader();
              reader.onload = () => {
                const dataUrl = reader.result;
                // Establecer la URL de datos como el valor del atributo 'content' de la etiqueta meta 'og:image'
                document.querySelector('meta[property="og:image"]').setAttribute('content', logoimagen);
              };
              reader.readAsDataURL(blob);
            })
            .catch(error => {
              console.error('Error al obtener la imagen:', error);
            });
        }
      } catch (error) {
        console.error('Error al obtener la imagen:', error);
      }
    }
  }

 

  

  // Renderiza el componente
  render() {
    // Estilos del componente
    const StyleDragArea = styled.div`
      .btn_consultar {
        background-color: #d14081;
        color: white;
        width: 200px;
        margin: auto;
        font-weight: 600;
        border-radius: 6px;
        border: 1px solid #d14081;
      }
      .btn_consultar:hover {
        background-color: white;
        border: #d14081 1px solid;
        color: #d14081;
      }
      .btn_consultar:focus {
        color: white;
        background-color: #932C59;
        box-shadow: 0 0 0 1px rgb(255 39 136);
      }
      .btn_consultar:active {
        color: white !important;
        background-color: #932C5A !important;
        border-color: #932c5a !important;
      }
      .boton_amarillo_comienza {
        width: 250px !important;
        height: 49px;
        font-size: 20px;
      }
      .boton_amarillo {
        padding: 5px 22px;
        font-size: 18px;
        background-color: #FFEC00;
        color: #484848;
        border: none;
        font-weight: 400 !important;
        border-radius: 5px;
      }
      .boton_amarillo:hover {
        border: 1px solid #FFEC00;
        background-color: white;
        color: #6e6e6e;
      }
      .boton_amarillo:not(:disabled):not(.disabled).active, 
      .boton_amarillo:not(:disabled):not(.disabled):active, 
      .show > .boton_amarillo.dropdown-toggle {
        color: #fff;
        background-color: #BFB31C;
        border-color: #938a1e;
      }
    `;



    console.log(this.state);
    return (
      <>
        {/* Sección para mostrar el formulario de búsqueda del folio */}
        {this.state.FormSeguimiento === '' || this.state.ListOperaciones.length === 0 ? (
          <div className="fondo_seg mt-0">
            
            <Container sx={{ padding: '0px 16px !important', maxWidth: '1112px !important' }}>
              <div className="container">
                <div style={{ paddingTop: '2%' }}>
                  <div className="col-md-12 mt-4 mb-4">
                    <Typography variant="h2" fontWeight='600 !important' sx={{ fontSize: '40px', color: '#d14081', fontFamily: 'itaca' }}>Seguimiento</Typography>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5 mb-5">
                    <p style={{ fontSize: '15px', lineHeight: '33.05px', color: 'black' }} className="card-text style_txt">
                      Si tienes un servicio contratado con nosotros ingresa aquí <font className="estilo_rosa"> tu número de folio </font> que puedes encontrar en tu comprobante de pago <font className="estilo_rosa"> y el apellido </font> para corroborar el status de tu set</p>
                  </div>
                  <div className="col-md-1"></div>
                  <div className="col-md-6 ">
                    <Container maxWidth='lg'>
                      <div style={{ fontSize: '19px' }} className="cuadro_seg">
                        <div className="form-group">
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <label style={{ marginTop: '7px' }} className="input_seg labels" htmlFor="input1">Ingresa No. de folio<font className="text-danger">*</font> </label>
                            <IconButton onClick={this.ShowModalFolio} aria-label="quest">
                              <HelpOutlineIcon sx={{ padding: '0px', fontSize: '24.67px', color: '#d14081' }} />
                            </IconButton>
                          </Box>
                          <input onChange={this.handleChange} name="Folio" type="text" placeholder="Número de 12 dígitos" className="form-control" id="input1" />
                        </div>
                        <div className="form-group mb-4">
                          <label className="input_seg labels" htmlFor="input2">Apellido del finado<font className="text-danger">*</font></label>
                          <input onChange={this.handleChange} name="ApPaterno" type="text" placeholder="Apellido" className="form-control" id="input2" />
                          <p className="Obligatorio mb-1">*Obligatorio</p>
                        </div>
                        {/* Muestra un mensaje de error si existe */}
                        {this.state.Error === '' ? (
                          <></>
                        ) : (
                          <Box mt='5px' mb='10px'>
                            <AlertMui Text={this.state.Error} Time={50000} Type="error" OnChange={() => this.setState({ Error: '' })} />
                          </Box>
                        )}
                        {/* Botón para consultar el seguimiento */}
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                          <StyleDragArea>
                            <button disabled={this.state.form.Folio !== '' && this.state.form.ApPaterno !== '' ? false : true} onClick={this.ShowSeguimiento} data-animation-in="slideInRight" type="button" className="btn btn-block btn_consultar">Consultar</button>
                          </StyleDragArea>
                        </div>
                      </div>
                    </Container>
                  </div>
                </div>
                
                {/* Modal para mostrar información sobre el folio */}
                {this.state.ModalFolio === true ? (
                  <ModalBaseMui Title='' Open={this.state.ModalFolio} FullScreen={false} OnClickClose={this.ShowModalFolio} FullWidth={true} MaxWidth='sm'
                    ContentModal={
                      <Container maxWidth='sm'>
                        <div className="modal-body pt-0">
                          <h5 className="color_ros modal-title text-center pb-3" id="exampleModalLabel">¿Dónde encuentro mi folio?</h5>
                          <p className="textofolio">
                            Tu número de folio se envió al correo electrónico registrado durante la compra del set. Si no cuentas
                            con el correo, comunícate al <font className="estilo_rosa"> 000-000-0000. </font>
                          </p>
                          <div className="">
                            <img src={this.state.FormConmemoracion === '' ? ImagenFolio : this.state.FormConmemoracion.fotoVideo} alt="Imagen en el Pop-up" className="img-fluid" />
                          </div>
                        </div>
                      </Container>

                    }
                  />
                ) : (
                  <></>
                )}
              </div>
            </Container>
          </div>
        ) : (
          // Si se encontraron datos de seguimiento y operaciones, muestra el componente SeguimientoDetalle
          <SeguimientoDetalle Origen={this.state.Origen} OnClickReturn={this.OnClickReturn} DataForm={this.state.form} Host={TodoDataService.GetHost()} DataCaratula={this.state.FormCaratula} CompartirSeguimiento={this.CompartirSeguimiento} ListOperaciones={this.state.ListOperaciones} DataSeguimiento={this.state.FormSeguimiento} DataConmemoracion={this.state.FormConmemoracion} ShowModalCompartir={this.ShowModalCompartir} ModalCompartir={this.state.ModalCompartir} StyleDragArea={StyleDragArea} />
        )}
      </>
    );
  }
}

export default withRouter(Seguimiento);
