//-------------Importar librerias----------------------------
import React, { useEffect,useState } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
// -----------Importar Componentes de Terceros---------------------
import Typography from '@mui/material/Typography';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';

// -----------Importar Componentes Personalizados------------------
import RadioGroupImagen from "../../../components/RadioGroup-Imagen";


// -----------Importar Archivos Estaticos------------------


import StepWhatsApp from '../../../assets/Imagenes/Sets/Stepss/Grupo Whatsapp.png'
import StepPdf from '../../../assets/Imagenes/Sets/Stepss/Grupo Descargar.png'
import StepCorreo from '../../../assets/Imagenes/Sets/Stepss/Grupo email.png'
import StepImprimir from '../../../assets/Imagenes/Sets/Stepss/Grupo Imprimir.png'

import StepWhatsApp_B from '../../../assets/Imagenes/Sets/Stepss/Grupo Whatsapp-B.png'
import StepPdf_B from '../../../assets/Imagenes/Sets/Stepss/Grupo Descargar-B.png'
import StepCorreo_B from '../../../assets/Imagenes/Sets/Stepss/Grupo email-B.png'
import StepImprimir_B from '../../../assets/Imagenes/Sets/Stepss/Grupo Imprimir-B.png'

// Componente funcional StepConfirmacion
const StepConfirmacion = ({Estado,Municipio,Colonia,FormFinado,FormPago,ListCarritoExtras,ListProductos, ListCarrito,TotalServicioDespedida, SetNombreSeleccionado,ListTypo,TipoDespedida, SubTotalExtra, SubTotal,formatearComoMonedaMXN, CallNumber,PedidoCliente, OnChange, SwitchHandleChange }) => {
    // Definición de opciones para el RadioGroup
    const [PdfConfirmacionPago, setPdfConfirmacionPago] = useState('')
    const ContentRadion2 = [{ Id: 0, IconPasivo: StepWhatsApp, IconActivo: StepWhatsApp_B, Disabled: false }, { Id: 1, IconPasivo: StepPdf, IconActivo: StepPdf_B, Disabled: false }, { Id: 2, IconPasivo: StepCorreo, IconActivo: StepCorreo_B, Disabled: false }, { Id: 3, IconPasivo: StepImprimir, IconActivo: StepImprimir_B, Disabled: false }]



    const generatePdf = () => {
        const input = document.getElementById('imagen');
        let maxWidth = 210; // Ancho de la página en mm (A4)
        const maxHeight = 297; // Largo de la página en mm (A4)

        html2canvas(input)
            .then((canvas) => {
                let imgWidth = canvas.width;
                let imgHeight = canvas.height;

                // Redimensionar la imagen si el alto es mayor que el largo de una hoja A4
                if (imgHeight > maxHeight) {
                    const ratio = maxHeight / imgHeight;
                    imgWidth *= ratio;
                    imgHeight *= ratio;
                    maxWidth = imgWidth < 210 ? imgWidth : 210
                }

                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();

                // Establecer márgenes en mm (A4)
                const marginLeft = 30;
                const marginTop = 20;
                const marginRight = 30;
                const marginBottom = 150;

                console.log(maxWidth)

                pdf.addImage(imgData, 'PNG', marginLeft, marginTop, maxWidth - marginLeft - marginRight, imgHeight - marginTop - marginBottom);
                pdf.setProperties({
                    title: "MyTitle.pdf"
                });
                setPdfConfirmacionPago(pdf)

            })
            .catch((error) => {
                console.error('Error generating PDF:', error);
            });
    };


    // Efecto secundario para realizar una acción cuando el componente se monta
    useEffect(() => {
        // Llamada a la función SwitchHandleChange con el argumento 'form' al montar el componente
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        };

        scrollToTop();
        SwitchHandleChange('form');
        generatePdf();
    }, []);


    const OnChangeConfirmacionPago = (e) => {
        if (e.target.value === '0') {
            window.open(`https://web.whatsapp.com/send?text=${'Folio de Sevicio:' +PedidoCliente.folio }`, "_blank");
        }
        if (e.target.value === '1') { PdfConfirmacionPago.save('converted.pdf'); }
        if (e.target.value === '2') {
            window.open('mailto:' + "?subject=Contratacion%20de%20servicio&body=Folio%20de%20Servicio:%20" +PedidoCliente.folio , "_blank");
            //  + encodeURIComponent(Email) + "?subject=Contratacion%20de%20servicio&body=Estoy%20interesado%20en%20contratar&attachment="+
        }
        if (e.target.value === '3') {
            window.open(PdfConfirmacionPago.output('bloburl'));
        }

    }

    // Renderizado del componente
    console.log(PedidoCliente);
    return (
        <div id='imagen'>


            {/* Sección de mensaje de agradecimiento */}
            <div className="col-md-12" >
            <Typography variant='h4' sx={{ color: '#D14081', fontSize: '25px', fontWeight: '600 !important' }} >Datos de la confirmación</Typography>
            </div>
            <div className="col-md-12" style={{ marginBottom: '10%' }}>
                <Typography sx={{ fontSize: '20px' }} >Gracias <Typography display="inline" sx={{ color: '#D14081', fontSize: '20px' }} >@{PedidoCliente.contactoCliente.nombreCompleto}</Typography>  cliente enviamos la confirmación al correo registrado:<Typography display="inline" sx={{ color: '#D14081', fontSize: '20px' }} >{PedidoCliente.contactoCliente.email}</Typography> </Typography>
            </div>
            {/* Sección del número de folio */}
            <div className="col-md-12" style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography sx={{ fontSize: '20px' }}>Tu número de folio es:</Typography>
            </div>
            <div style={{ marginTop: '10px ', display: 'flex', justifyContent: 'center' }}>
                <Typography sx={{ padding: '2%', borderRadius: '10px', fontSize: '35px', backgroundColor: '#FFEC00' }}> <ConfirmationNumberIcon sx={{ color: 'white', transform: 'rotate(90deg)', fontSize: '50px' }} /> {PedidoCliente.folio}</Typography>
            </div>
            {/* Sección de RadioGroup con imágenes */}
            {
                PdfConfirmacionPago !== ''
                    ?
                    <RadioGroupImagen WidthImagen='50%' OnChange={OnChangeConfirmacionPago} Origen='Row' Name='TipoPdf' Width='md' JustifyContent='space-around' Content={ContentRadion2} />
                    :
                    <div style={{marginBottom:'15x'}} ></div>
            }
            {/* Sección de contacto para iniciar el servicio */}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Typography sx={{ fontSize: '24px' }}> Para <Typography display="inline" sx={{ fontSize: '24px', color: '#D14081' }}>iniciar con tu servicio</Typography> , comunícate al </Typography>
            </div>
            <div onLoad={generatePdf} style={{ marginBottom: '10px', marginTop: '10px ', display: 'flex', justifyContent: 'center' }}>
                <Typography onClick={() => (window.open('tel:'+CallNumber, "_blank"))} sx={{ cursor:'pointer', padding: '1%', borderRadius: '10px', fontSize: '24px', backgroundColor: '#FFEC00' }}> {CallNumber.slice(3)}</Typography>
            </div>
        </div>
    );
}

// Exportación del componente StepConfirmacion
export default StepConfirmacion;