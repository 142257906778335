//-------------Importar librerias----------------------------
import React, { useEffect, useRef, useState } from 'react';
import * as htmlToImage from 'html-to-image';
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';

// -----------Importar Componentes de Terceros------------------
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ImageListItem from '@mui/material/ImageListItem';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import ReactPlayer from 'react-player/lazy'
// -----------Importar Componentes Personalizados------------------
import TarjetaMensaje from "../../../components/TarjetaMensaje";
import AudioPlayer from '../../../components/Audio_Player';

// -----------importa Archivos Estaticos------------------
import MaskConmemoracionNoPlay from '../../../assets/Imagenes/Mask group no play.png'

import '../css/styles.css'



const StepConmemoracionTercero = ({Compartir, RemotoVideo, Song, Action, FormActualizar, InstrumentalFile, ContadorMemoria, OnchangeVideoConmemoracion, DataMemoria, ListMensajes, DataFinado, OnclickAction, DataAction, DataNuevaMemoria, Origen }) => {
    const [ImagenConmemoracion, setImagenConmemoracion] = useState('');
    const [ImagenMensajeConmemoracion, setImagenMensajeConmemoracion] = useState('');
    const [VideoConmemoracion, setVideoConmemoracion] = useState('');
    const [playingIndex, setPlayingIndex] = useState(0);
    const [ListVideos, setListVideos] = useState([]);
    const [Play, setPlay] = useState(false);
    const [VolumenVideo, setVolumenVideo] = useState(0);

    const [CurrentTime, setCurrentTime] = useState(0);
    const [Seek, setSeek] = useState(0);
    const RefVideo = useRef();




    const ElementImagenRef = useRef(null);
    const ElementMensajeRef = useRef(null);


    const Ffmpeg = createFFmpeg({
        log: true
    })



    const htmlToImageConvert = async () => {
        if (Origen === 'Nueva Conmemoracion') {
            const dataUrl = await htmlToImage.toPng(ElementImagenRef.current);


            const dataUrl2 = await htmlToImage.toPng(ElementMensajeRef.current);
            setImagenConmemoracion(dataUrl)
            setImagenMensajeConmemoracion(dataUrl2)
            await CreateVideoConmemoracion(dataUrl, dataUrl2)
        }

    };

    const b64ToBlob = async (b64) => {
        const blob = await fetch(b64);
        return await blob.blob();
    };


    const blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                resolve(reader.result.split(','));
                // "data:image/jpg;base64,    =sdCXDSAsadsadsa"
            };
        });
    };



    const CreateVideoConmemoracion = async (item, item2) => {

        await Ffmpeg.load();
        if (Origen === 'Nueva Conmemoracion') {
            Ffmpeg.FS(
                'writeFile',
                InstrumentalFile.name,
                await fetchFile(InstrumentalFile),
            )


            let item1blob = await b64ToBlob(item)
            let item2lob = await b64ToBlob(item2)



            let ImagenConmemoracionFile = new File([item1blob], 'ImagenConmemoracion1.png', {
                type: "image/png",
            })


            let MensajeConmemoracionFile = new File([item2lob], 'ImagenConmemoracion2.png', {
                type: "image/png",
            })

            Ffmpeg.FS(
                'writeFile',
                ImagenConmemoracionFile.name,
                await fetchFile(ImagenConmemoracionFile),
            )

            Ffmpeg.FS(
                'writeFile',
                MensajeConmemoracionFile.name,
                await fetchFile(MensajeConmemoracionFile),
            )

            await Ffmpeg.run("-framerate", "1/15", "-i", "ImagenConmemoracion%d.png", "-i", InstrumentalFile.name, "-c:v", "libx264", "-t", "30", "-pix_fmt", "yuv420p", '-filter:v', "scale=1920:1080", "out.mp4");

            const data = Ffmpeg.FS('readFile', 'out.mp4');
            const VideoBlob = new Blob([data.buffer], { type: "video/mp4" })
            const url = URL.createObjectURL(
                new Blob([data.buffer], { type: "video/mp4" }),
            );


            setVideoConmemoracion(url);
            OnchangeVideoConmemoracion.Function(await blobToBase64(VideoBlob), OnchangeVideoConmemoracion.Key, OnchangeVideoConmemoracion.Name);

        }


    }



    const [validated, setValidated] = useState('false');

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

        }


        if (form.checkValidity(DataAction) === true) {
            let Imagen = await fetch(FormActualizar.ImagenCrop).then(res => res.blob())
            let ImagenBase64 = await blobToBase64(Imagen)
            let Data = { Close: DataAction.Close, Refresh: DataAction.Refresh, Token: DataAction.Token, Url: DataAction.Url, Conmemoracion: { FolioOrigen: DataAction.PedidoCliente.folio, OperacionOrigen: DataAction.PedidoCliente.operacion, Mensaje: FormActualizar.mensaje === 50 ? FormActualizar.mensajepersonalizado : ListMensajes.find(data => data.value === FormActualizar.mensaje).label, FotoVideo: ImagenBase64[1], ExtencionFoto: ImagenBase64[0], Video: FormActualizar.VideoConmemoracion[1], ExtencionVideo: FormActualizar.VideoConmemoracion[0] }, SetError: DataAction.SetError }
            OnclickAction(Data);
        }
        setValidated('');

    };

    const HandlePlay = () => {
        console.log('Video is playing');
        setPlay(true)

    };

    const HandlePause = () => {
        console.log('Video is paused');
        setPlay(false)
    };


    const HandleProgress = (state) => {
        setCurrentTime(parseFloat(state.playedSeconds));
    };



    const HandleSeek = (seconds) => {
        setSeek(seconds - CurrentTime);
    };



    useEffect(() => {
        const GenerarListVideos = async () => {

            if (ListVideos.length === 0) {
                const blob = await fetch(DataNuevaMemoria.Conmemoracion.extencionVideo + ',' + DataNuevaMemoria.Conmemoracion.video);
                let listvideos = [URL.createObjectURL(await blob.blob())]
                if (DataNuevaMemoria.Conmemoracion.dconmemoraciones.length > 0) {
                    await Promise.all(
                        DataNuevaMemoria.Conmemoracion.dconmemoraciones.map(async (element) => {
                            const blobmemoria = await fetch(element.extencionVideo + ',' + element.video);
                            listvideos.push(URL.createObjectURL(await blobmemoria.blob()))
                        }
                        )
                    )
                    setListVideos(listvideos)
                }
                else {
                    setListVideos(listvideos)
                }
            }
            else if (ListVideos.length > 0) {
                let nuevovideo = DataNuevaMemoria.Conmemoracion.dconmemoraciones.at(-1)
                let nuevovideoblob = await fetch(nuevovideo.extencionVideo + ',' + nuevovideo.video);
                let nuevovideourl = URL.createObjectURL(await nuevovideoblob.blob())
                let listvideos = ListVideos
                listvideos.splice(1, 0, nuevovideourl);
                setListVideos(listvideos);
                console.log(ListVideos.length);
                setPlayingIndex(1);
            }
        }


        if (Origen === 'Nueva Memoria' && DataNuevaMemoria.Conmemoracion !== '') {
            GenerarListVideos();
        }

    }, [DataNuevaMemoria]);

   // Función para forzar el volumen a 0
   const forceVolumeToZero = () => {
    if (RefVideo.current) {
        RefVideo.current.getInternalPlayer().volume = 0;
    }
  };

  // useEffect para establecer el volumen inicial a 0
  useEffect(() => {
    forceVolumeToZero();
  }, []);

  const handleVolumeChange = () => {
    // Siempre restablecer el volumen a 0 cuando se cambia
    forceVolumeToZero();
  };

  console.log(playingIndex);
  console.log(DataNuevaMemoria);

    return (
        <>
            <Box component="form" noValidate validated={validated} onSubmit={handleSubmit} sx={{ flexGrow: 1 }}>
                <AudioPlayer Source={Song} isPlaying={playingIndex <= 1 && Compartir === true && Play===false ?false:Play} volume={0.8} currentTime={Seek} />
                <Grid
                    container
                    columns={{ xs: 4, sm: 6, md: 12 }}
                >
                    {
                        Origen === 'Nueva Memoria'
                            ?
                            <Grid item xs={7} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box width='100%' >
                                    {
                                        ListVideos.length > 0
                                            ?
                                            <ReactPlayer playing={playingIndex <= 1 && Compartir === true ?false:RemotoVideo} muted volume={VolumenVideo} onVolumeChange={handleVolumeChange} onSeek={HandleSeek} onPlay={HandlePlay} onPause={HandlePause} onProgress={HandleProgress} ref={RefVideo} width='100%' height='100%' className='react-player  ' url={ListVideos[playingIndex]} onEnded={() => setPlayingIndex(playingIndex + 1 === ListVideos.length ? 0 : playingIndex + 1)} controls />
                                            :
                                                 <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                                                    <LinearProgress sx={{ backgroundColor: 'rgba(209, 64, 129, .3)', '& .MuiLinearProgress-bar': { backgroundColor: '#D14081' } }} size={100} color="secondary" />
                                                </Stack>
                                    }
                                </Box>
                            </Grid>
                            :
                            <></>
                    }
                    {
                        Origen === 'Nueva Conmemoracion'
                            ?
                            <Grid item xs={7} sx={{ display: 'flex', alignItems: 'center' }}>
                                {
                                    FormActualizar.picture === ''
                                        ?
                                        <></>
                                        : VideoConmemoracion === ''
                                            ?
                                            <Box width='100%'>
                                                <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                                                    <LinearProgress sx={{ backgroundColor: 'rgba(209, 64, 129, .3)', '& .MuiLinearProgress-bar': { backgroundColor: '#D14081' } }} size={100} color="secondary" />
                                                </Stack>
                                            </Box>
                                            : <div className='player-wrapper'>
                                                <ReactPlayer playing={RemotoVideo} muted onSeek={HandleSeek} onPlay={HandlePlay} onPause={HandlePause} ref={RefVideo} width='100%' height='100%' className='react-player  ' url={VideoConmemoracion} controls />
                                            </div>
                                }
                            </Grid>
                            :
                            <></>
                    }
                    <Grid item xs={6} md={5} sx={{ display: 'flex' }}  >
                        <Box display='flex' alignItems='center' flexDirection='column' sx={{ width: '100%', justifyContent: 'space-between' }} >
                            <Box sx={{ width: { xs: '100%', md: '95%' }, padding: { xs: '3% 0% 3% 0%', md: '3% 3% 3% 2%' }, borderRadius: '20px' }}>
                                <Typography fontSize='34px' fontFamily='Itaca' fontWeight='700 !important' color='#404040' >{DataFinado.nombreCompleto}</Typography>
                                <Typography color='#404040' fontSize='24px' fontFamily='Itaca ' fontWeight='700 !important'  >{DataFinado.agnoNacimiento} - {DataFinado.agnoDefuncion}</Typography>
                                <Typography sx={{ paddingTop: '5%' }} color='#D14081' fontWeight='700 !important' fontSize='24px' fontFamily='Itaca ' >Servicio: {DataFinado.setFunerario} </Typography>
                                <Typography fontSize='22px' fontFamily='Itaca ' color='#404040' >
                                    Libro de visitas: {Origen === 'Nueva Memoria' ? DataNuevaMemoria.Conmemoracion.visitas : ''}
                                </Typography>
                            </Box>
                            <Box sx={{ width: '96%', padding: '0% 0% 0% 4%', borderRadius: '0px' }}>
                            </Box>
                            <Box sx={{ display: "flex", width: { xs: '100%', md: '97%' }, padding: { xs: '2% 0% 3% 0%', md: '2% 3% 3% 0%' }, borderRadius: '20px' }}>
                                {Action}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>


            {
                ImagenConmemoracion === '' && ImagenMensajeConmemoracion === '' && Origen === 'Nueva Conmemoracion'
                    ?
                    <Box marginTop='300%'>
                        <Box width='1920px' sx={{ display: ImagenConmemoracion !== '' ? 'none' : 'block' }}>
                            <div ref={ElementImagenRef}>
                                <ImageListItem  >
                                    <img style={{ filter: 'sepia(0.9)' }} width='100%' src={FormActualizar.ImagenCrop} alt="imagen recortar" />
                                    <ImageListItem sx={{ position: 'absolute', bottom: '0%' }} >
                                        <img width='1920px' src={MaskConmemoracionNoPlay} alt="imagen fondo" />
                                    </ImageListItem>
                                </ImageListItem>
                            </div>
                        </Box>



                        {
                            ImagenMensajeConmemoracion === ''
                                ?
                                <Box width='1920px' sx={{ display: ImagenMensajeConmemoracion !== '' ? 'none' : 'block' }}>
                                    < TarjetaMensaje OnLoad={htmlToImageConvert} Nombre={DataFinado.nombreCompleto} Mensaje={FormActualizar.mensaje === 50 ? FormActualizar.mensajepersonalizado : ListMensajes.find(data => data.value === FormActualizar.mensaje).label} Sexo={DataFinado.sexo} Width='12%' ElementRef={ElementMensajeRef} FontSize={80} />
                                </Box>

                                : <></>
                        }
                    </Box>
                    : <></>
            }


        </>
    );
}

export default StepConmemoracionTercero