import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import './style.css'

const AcuerdoTerminosCondiciones = ({ }) => {

    return (
        <Box width='100%'>

            <Typography className='textotitulo'>
                TÉRMINOS Y CONDICIONES DE USO DE PÁGINA WEB “Columba”
            </Typography>

            <Typography className='textonegritas'>
                Por favor lea los siguientes Términos y Condiciones antes de utilizar nuestros sistemas de información electrónicos.
            </Typography>

            <Typography className='textonegritas'>
                Actualizados al lunes 13 de noviembre del 2024
            </Typography>

            <Typography className='textobase'>
                Le informamos que el Sitio es propiedad de Columba Funerales, S.A. de C.V. (“<Typography className='textonegritas inline'>COLUMBA”</Typography>), quien es
                comercialmente conocido como Columba y contamos con Registro Federal de Contribuyentes CFU210521IZ2.
            </Typography>

            <Typography className='textobase'>
                Este contrato describe los Términos y Condiciones que establecen las disposiciones, derechos y obligaciones a las que le sujetará y obligará en su calidad de “Usuario”, al momento
                de ingresar o hacer uso del sitio web www.columbafunerales.com , así como de las plataformas digitales del Columba Funerales, S.A. de C.V (todos los anteriores serán llamados
                como el “Sitio”). Buscamos en todo momento, garantizarle, transparentarle, informarle y orientarle adecuadamente sobre la navegación a través de nuestro Sitio, comprometiéndonos
                a mantener siempre públicos estos Términos y Condiciones.
            </Typography>

            <Typography className='textobase'>
                A su vez, el usuario se sujetará a todas y cada una de las condiciones generales y particulares incluidas en los Términos y Condiciones de Uso aquí contenidos, así como de todas
                las leyes y reglamentos aplicables de conformidad a la legislación vigente y aplicable para el uso del Sitio y declara tácitamente su aceptación utilizando para tal efecto
                medios electrónicos, en términos de lo dispuesto en el artículo 1803 del Código Civil Federal de los Estados Unidos Mexicanos.
            </Typography>

            <Typography className='textobase'>
                En caso de que sean violados estos Términos y Condiciones, <Typography className='textonegritas inline'>COLUMBA</Typography> tiene la facultad de cancelar su uso
                de La Página Web, excluirlo del uso en operaciones que pretenda realizar en el Sitio en un futuro, cancelar beneficios que haya recibido a través del Sitio, y/o tomar
                la acción legal que juzgue conveniente para sus intereses.
            </Typography>

            <Typography className='textobase'>
                Todos los productos y/o servicios que son ofrecidos por La Página Web pudieran ser creados, cobrados, o presentados en otras páginas web propiedad de
                <Typography className='textonegritas inline'> COLUMBA</Typography> y en tal caso estarían sujetas a sus propios Términos y Condiciones,
                asimismo, en lo no previsto en dichas páginas Web y sus términos y condiciones se estará sujeto a lo señalado en los presentes términos y condiciones.
            </Typography>

            <Typography className='textobase'>
                Usted puede limitar el uso o divulgación de sus datos personales para dejar de recibir mensajes publicitarios o promocionales, registrando su número
                telefónico en el "Registro Público Para Evitar Publicidad" de la Procuraduría Federal del Consumidor. Para más información al respecto, visite la página
                de internet www.profeco.gob.mx
            </Typography>

            <Typography className='textobase'>
                Nuestros medios de contacto para todo lo relacionado a los presentes Términos y Condiciones son:
            </Typography>

            <Box>

            </Box>

            <ul className='listaacuerdo listsizeupp'>
                <li>
                    <Typography className='textonegritas inline'>Domicilio:</Typography> Av. Alfredo del Mazo 2601, Interior 2, Colonia La Magdalena, en la ciudad de Toluca, Estado de México, México, con Código Postal 50010.
                </li>
                <li>
                    <Typography className='textonegritas inline'>Teléfono:</Typography> +52 722 830 6071
                </li>
                <li>
                    <Typography className='textonegritas inline'>Horario de atención:</Typography> El horario de atención es de 09:00 a 16:00 horas
                </li>
                <li>
                    <Typography className='textonegritas inline'>Correo electrónico: </Typography> m.de.gfan@gmail.com
                </li>
                <li>
                    <Typography className='textonegritas inline'>Nuestra página web: </Typography> http://www.columbafunerales.com/
                </li>
            </ul>

            <Typography className='textobase'>
                El Sitio estará disponible a través de diversas plataformas electrónicas que permiten su uso gratuito, las cuales serán:
            </Typography>

            <ol className='listaacuerdo '>
                <li className='bold'> Página web:
                    <ol className='sublistaacuerdo' type='a'>
                        <li>http://www.columbafunerales.com/</li>
                    </ol>
                </li>
                <li className='bold'> WhatsApp:
                    <ol className='sublistaacuerdo' type='a'>
                        <li>+52 722 784 6600</li>
                    </ol>
                </li>
                <li className='bold'> Redes Sociales:
                    <ol className='sublistaacuerdo' type='a'>
                        <li>FALTA AGREGAR ENLACE DE REDES SOCIALES CUANDO ESTEN HABILITADAS</li>
                    </ol>
                </li>
            </ol>

            <Typography className='textonegritas'>
                CLÁUSULA 1. DISPONIBILIDAD, ACCESIBILIDAD O INTERRUPCIÓN DEL SITIO.
            </Typography>

            <Typography className='textobase'>
                Nos comprometemos a intentar mantener el Sitio siempre disponible. Como requisito para acceder al Sitio, deberá de contar con la mayoría de edad de conformidad con la legislación
                aplicable, absteniéndose a ingresar si tiene algún impedimento legal para llevar a cabo actos jurídicos. Nos comprometemos a no establecer preferencias injustificadas o indebidas
                o discriminación alguna respecto a los Usuarios del Sitio.
            </Typography>
            <Typography className='textobase'>
                En caso de que por alguna razón no se permita la accesibilidad o se lleve a cabo una interrupción en el Sitio, podrá reportar tal situación en los medios de contacto aquí establecidos
                , a efectos de que podamos solucionar el problema a la brevedad posible.
            </Typography>

            <Typography className='textonegritas'>
                CLÁUSULA 2. DOMICILIO Y ÁREA DE SERVICIOS.
            </Typography>

            <Typography className='textobase'>
                Al hacer uso del Sitio, reconoce que nuestro domicilio está ubicado en Av. Alfredo del Mazo 2601, Interior 2, Colonia La Magdalena, en la ciudad de Toluca, Estado de México,
                México y el alcance y área de nuestras operaciones de servicio serán solamente en nuestras instalaciones. Las ofertas, promociones, bienes, productos o servicios que publiquemos,
                salvo que se indique expresamente lo contrario, serán únicamente válidas y estarán limitadas a las áreas antes mencionadas y sujetas a disponibilidad de nuestra parte.
            </Typography>

            <Typography className='textonegritas'>
                CLÁUSULA 3. CONTACTO DEL USUARIO, REGISTRO Y CUENTAS.
            </Typography>

            <Typography className='textobase'>
                Como Usuario del Sitio, podrá conocer nuestros bienes, productos o servicios del Sitio y podrá ponerse en contacto con nosotros por medio del llenado del formulario correspondiente
                con sus datos y el mensaje que quieras enviarnos, así mismo podrá crear una cuenta, por lo que será su responsabilidad mantener la confidencialidad de la información de su cuenta
                y contraseña, así como de todas las actividades que se realicen bajo su cuenta. Si sospecha que su cuenta ha sido utilizada de manera distinta o no autorizada, deberá notificarnos
                inmediatamente.
            </Typography>
            <Typography className='textobase'>
                Respecto de la cuenta en mención podrá registrarse o iniciar sesión en La Página Web mediante redes sociales, para lo cual tendrá la responsabilidad de revisar el Aviso de Privacidad
                de estos servicios, liberando de toda responsabilidad a La Página Web por el uso que dichos servicios brinden a sus datos.
            </Typography>
            <Typography className='textobase'>
                En todo caso, los datos proporcionados por el Usuario serán utilizados y protegidos por COLUMBA de conformidad al Aviso de Privacidad que podrá visualizar en La Página Web.
            </Typography>
            <Typography className='textobase'>
                Nos comprometemos a utilizar la información que nos proporcione de forma confidencial, asegurándole que no vamos a difundirla o transmitirla a terceros, salvo autorización
                expresa de su parte o por requerimiento de alguna autoridad competente.
            </Typography>

            <Box >
                <Typography className='subclausula bold'>a. Otorgamiento de su información.</Typography>
                <Typography className='textobase'>
                    Respecto de la cuenta en mención podrá registrarse o iniciar sesión en La Página Web mediante redes sociales, para lo cual tendrá la responsabilidad de revisar el Aviso de Privacidad
                    de estos servicios, liberando de toda responsabilidad a La Página Web por el uso que dichos servicios brinden a sus datos.
                </Typography>
            </Box>

            <Box >
                <Typography className='subclausula bold'>b.	Responsabilidad respecto de la información que otorgues.</Typography>
                <Typography className='textobase'>
                    Usted es el único responsable de la veracidad, exactitud y certeza de los datos y la información que nos proporcione para contactarnos. Cualquier error o perjuicio derivado de
                    información y datos erróneos otorgados de su parte, nos liberará de toda responsabilidad que pudiera ocasionarse, siendo usted el único responsable de un hecho relacionado.
                </Typography>
            </Box>

            <Box >
                <Typography className='subclausula bold'>c.	De los beneficios y ofertas.</Typography>
                <Typography className='textobase'>
                    COLUMBA pondrá a disposición de todos los usuarios diversos descuentos y promociones exclusivos, ofrecidos por COLUMBA, por lo que el Usuario acepta y reconoce que los servicios y/o
                    productos, mismos que se concertarán de manera directa entre el Usuario y COLUMBA, por lo que, para tal efecto, el Usuario deberá presentar el código, número y/o cupón
                    correspondiente al descuento, o beneficio otorgado en La Página Web, las cuales serán temporales y no serán acumulables con otras promociones. Los Servicios ofrecidos por COLUMBA
                    se regirán por el contrato, convenio o membresía que, para tal efecto, se genere y sea firmado por COLUMBA y el Usuario.
                </Typography>
            </Box>

            <Typography className='textonegritas'>
                CLÁUSULA 4. SERVICIOS QUE SE OFRECEN Y PROCEDIMIENTO PARA ADQUIRIR BIENES, PRODUCTOS O SERVICIOS EN EL SITIO.
            </Typography>

            <Typography className='textobase'>
                Los servicios ofrecidos por COLUMBA en la página web, podrán consistir, de manera enunciativa más no limitativa, en los enlistados a continuación:
            </Typography>

            <ul className='sublista listsizeupp' >
                <li>Servicios de crematorio;</li>
                <li>Servicios fúnebres;</li>
                <li>Servicios de embalsamiento de cadáveres;</li>
                <li>Servicios de arreglos de cadáveres con fines estéticos,</li>
                <li>Servicios de cremación,</li>
                <li>Servicios de inhumación;</li>
                <li>Servicios funerarios;</li>
                <li>Servicios de planes a futuro (servicios de adquisición de servicios funerarios de manera anticipada);</li>
                <li>Servicios de representación y asesoramiento jurídico en el ámbito funerario;</li>
                <li>
                    Servicios de comercialización de ataúdes, cofres funerarios, espejos, marcos, muebles para su uso en funerarias, mobiliario, muebles, pedestales (muebles), urnas funerarias,
                    monumentos funerarios metálicos, monumentos funerarios no metálicos, lapidas sepulcrales, lapidas conmemorativas, entre otros productos relacionados al ámbito funerario;
                </li>
                <li>
                    Servicios de aplicaciones móviles de comercio electrónico que permite a los usuarios realizar transacciones comerciales electrónicas a través de una red informática en el
                    ámbito funerario;
                </li>
                <li>Servicios de un software interactivo para los negocios en el ámbito funerario; </li>
                <li>Servicios de un software para realizar transacciones en línea en el ámbito funerario; </li>
                <li>
                    Servicios de un software para supervisar, analizar, controlar y ejecutar operaciones en el mundo físico, en el ámbito funerario;
                </li>
                <li>Servicios de un software para uso comercial en el ámbito funerario; </li>
                <li>Servicios de un software para uso comercial en el ámbito funerario; </li>
                <li>Servicios de un software y aplicaciones para dispositivos móviles en el ámbito funerario;</li>
                <li>Servicios de entretenimiento en el ámbito funerario; </li>
                <li>Servicios educativos en el ámbito funerario; </li>
                <li>Servicios de formación en el ámbito funerario; </li>
                <li>Servicios de suministro en línea de contenido de audio no descargable en el ámbito funerario;</li>
                <li>Servicios de producción y grabación de contenidos audiovisuales en el ámbito funerario; </li>
                <li>
                    Servicios de publicación de contenidos de entretenimiento multimedia, de audio y de vídeo digital en el ámbito funerario;
                </li>
                <li>Servicios de actividades culturales en el ámbito funerario; </li>
                <li>Servicios de organización y planeación de eventos sociales en el ámbito funerario; </li>
                <li>Servicios de organización y planeación de recepciones en el ámbito funerario;</li>
                <li>
                    Servicios científicos y tecnológicos, así como servicios de investigación y diseño conexos relacionados a la provisión de servicios funerarios, de inhumación y de cremación;
                </li>
                <li>
                    Servicios de análisis industrial, investigación y diseño industrial relacionados a la provisión de servicios funerarios, de inhumación y de cremación;
                </li>
                <li>
                    Servicios de control de calidad relacionados a la provisión de servicios funerarios, de inhumación y de cremación;
                </li>
                <li>
                    Servicios de diseño y desarrollo de equipos de hardware y software relacionados a la provisión de servicios funerarios, de inhumación y de cremación;
                </li>
                <li>Servicios de preparación de alimentos y bebidas en eventos funerarios; </li>
                <li>Servicios de provisión de alimentos y bebidas en eventos funerarios; </li>
                <li>Servicios de hospitalidad (hospitality) en el ámbito funerario; </li>

            </ul>

            <Typography className='textobase'>
                Por medio del uso del Sitio, podrá conocer nuestros bienes, productos o servicios, pero solamente podrá adquirirlos y contratarlos con el registro de una cuenta o en nuestras
                instalaciones. No realizamos ningún tipo de cobro por medio del Sitio ni canales que sean físicamente.
            </Typography>


            <Typography className='textobase'>
                El Usuario podrá realizar los pagos por medio del procesador de pagos que utiliza Columba, en este caso las transacciones serán efectuadas mediante la pasarela de Openpay.
            </Typography>

            <Typography className='textonegritas'>CLÁUSULA 5. SUS DERECHOS COMO CONSUMIDOR</Typography>

            <Typography className='textobase'>
                Como consumidor, siempre tendrá los siguientes derechos respecto de cualquier compra realizada con nosotros.
            </Typography>

            <ul className='listaacuerdo listsizeupp'>
                <li  >a. Devolución o Cancelación.</li>
                <li  >b. Cambios.</li>
                <li  >c. Garantías.</li>
            </ul>

            <Typography className='textobase'>
                Estos derechos serán variables dependiendo de cada bien, producto o servicio adquirido y deberá de consultar sus especificaciones en lo particular.
            </Typography>

            <Typography className='textonegritas'>
                CLÁUSULA 6. REVOCACIÓN DE SU CONSENTIMIENTO
            </Typography>

            <Typography className='textobase'>
                Podrá revocar su consentimiento a cualquier Compra realizada hasta 5 cinco días naturales posteriores a la entrega del bien o producto por nuestra parte, siempre y cuando no haya
                utilizado el bien o el servicio adquirido no haya sido brindado.
            </Typography>
            <Typography className='textobase'>
                Lo anterior procederá únicamente cuando el bien o producto de su Compra, haya sido conservado en el estado original en que le fue entregado. Nos obliga a informarle en las
                condiciones de compra de cada producto, aquellos bienes o productos que por su naturaleza no son objeto de devolución por revocación del consentimiento.
            </Typography>


            <Typography className='textonegritas'>
                CLÁUSULA 7. MEDIOS PARA COMUNICARNOS
            </Typography>

            <Typography className='textobase'>
                Acuerda y nos autoriza a asumir que toda información o comunicación de cualquier naturaleza que envíe desde cualquier dirección de correo electrónico o número telefónico registrado
                desde el Sitio, o aquellas comunicaciones realizadas a través del Sitio, serán vinculantes para usted como Usuario y se entenderán como una forma de manifestación expresa y por
                escrito de su voluntad, estas comunicaciones podrán ser conservada para los efectos legales que se puedan generar.
            </Typography>
            <Typography className='textobase'>
                También reconoce que cualquier mensaje que le enviemos por cualquier medio, lo tendrá por recibido de forma escrita. Acepta que todos los contratos, convenios, acuerdos, avisos y otras
                notificaciones o comunicaciones que realicemos por medios electrónicos o mediante el Sitio, satisfacen cualquier requisito legal de forma escrita.
            </Typography>


            <Typography className='textonegritas'>        
                
                CLÁUSULA 8. TÉRMINOS GENERALES DE LOS “SERVICIOS” Y “PEDIDOS”.
            </Typography>

            <Typography className='textobase'>
                Todos y cada uno de los Servicios que tenemos disponibles para su conocimiento, estarán regulados según estos Términos y Condiciones, así como los Anexos a estos Términos y Condiciones,
                los cuales serán identificados como Términos Adicionales.
            </Typography>
            <Typography className='textobase'>
                El Usuario reconoce que las ventas de los servicios enlistados en el numeral Cuarto se llevarán a cabo a través de La Página Web, o en las instalaciones de Columba. Para ello, se
                consideran los siguientes términos, reglas y condiciones generales para la provisión de dichos servicios:
            </Typography>

            <ul className='listaacuerdo listsizeupp'>
                <li className='bold' >Servicio: <Typography className='textobase inline'>
                Todos y cada uno de los Servicios que tenemos disponibles para su conocimiento, estarán regulados según estos Términos y Condiciones, así como los Anexos a estos Términos y Condiciones,
                los cuales serán identificados como Términos Adicionales.
            </Typography> </li>
                
                <li className='bold' >Pedido: <Typography className='textobase inline'> Se entenderá como tal al termino que se usará para el inicio de la compra comenzando desde la ventana en donde se recaba la información de datos de contacto. De no completar ninguna información no será contemplado como pedido. Estos pedidos tendrán una subdivisión en el que serán visibles para el Usuario en el sistema de escritorio con los siguientes términos:</Typography></li>
                <li className='bold' >Plazo de entrega del Pedido y Servicio: <Typography className='textobase inline'>Se entenderá que el plazo para para la entrega del Pedido o Servicio estará sujeto a disponibilidad de estos y a verificación de la información de contacto, servicio y pago realizado.</Typography></li>
            </ul>

        </Box>
    );
}

export default AcuerdoTerminosCondiciones;