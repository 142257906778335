// Importar React y otros módulos necesarios
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'

import axios from 'axios';
import styled from "styled-components";

// Importar componente de modal personalizado
import ModalBaseMui from '../../../components/Modal_Mui';

// Importar archivos CSS
import '../css/cobertura.css'
import '../css/main.css'

// Importar componentes de Material-UI y otros elementos necesarios
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { Typography } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';

// Valores constantes
const apiKey = 'AIzaSyDvwtKPpLTRP5rCJ7iJk_Em2mrhI41lC9I'; // Reemplaza 'TU_API_KEY' con tu clave de API de Google Maps
const Api = 'https://preprodcolumba.prophet.mx/api/';
const AnyReactComponent = ({ text }) => <div>{text}</div>;
const defaultProps = { center: { lat: 19.2823173, lng: -99.635527 }, zoom: 11 };

// Estilos CSS usando styled-components
const StyleDragArea = styled.div`
.btnAmarilloChico {
    background-color:  #ffec00 !important;
    border-radius: 5px;
    border: 1px solid  #ffec00 !important;
    color: black;
    font-weight: 400;
    width: 115px;
    font-size:22px
}

.btnAmarilloChico:hover {
    background-color: white !important;
}

.btnAmarilloChico:disabled {
  background-color: #c8cacb !important;
  border: 1px solid #c8cacb !important;
}

.btnRosaChico {
    background-color: var(--color-rosa);
    border-radius: 5px;
    border: 1px solid var(--color-rosa);
    color: white;
    font-weight: 400;
    width: 200px;
}


.btnRosaChico:hover {
    background-color: white;
    border: 1px solid var(--color-rosa);
    color:  var(--color-rosa);
  }
`;

const StyleButton = styled.div`
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  input[type=number] { -moz-appearance:textfield; }

  textarea:focus {
      background-color: white;
      color: black;
      border: 1px solid white !important;
      outline: none !important;
      outline-color: var(--color-amarilo) !important;
      box-shadow: 0 0 0 0.1rem var(--color-amarilo) !important;
    }
`;

// Componente principal CoberturaSets
const CoberturaSets = ({CallNumber, OnClickClose, SwitchHandleChange, FormCobertura, Token, ShowModalConsultarCodigoPostal, ModalConsultarCodigopostal, OnChange, DataEstado, DataMunicipio, DataColonia, HandleChange, OnClickPersonaliza }) => {
  // Definición de estados usando useState
  const [Covertura, setCovertura] = useState('');
  const [Info, setInfo] = useState(true);
  const [ErrorCovertura, setErrorCovertura] = useState(false);
  const [ShowMapa, setShowMapa] = useState(true);
  const [Mapa, setMapa] = useState({ lat: 0, lng: 0 });
  const refMapa = useRef(null);

  // Función para cargar el mapa según el código postal
  const CargarMapa = async (cp, cp2) => {
    // Llamar a la API de Google Maps Geocoding para obtener las coordenadas
    const respuesta = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${cp}&key=${apiKey}`
    )
    const result = await respuesta.json();
    // Actualizar el estado del mapa con las coordenadas obtenidas
    setMapa({
      lat: result.results[0].geometry.location.lat,
      lng: result.results[0].geometry.location.lng,
    });

    // Calcular la distancia entre puntos
    const distance = getDistanceBetweenPoints(result.results[0].geometry.location.lat, result.results[0].geometry.location.lng, 19.2536973, -99.5923868);
    setShowMapa(true);
    // Verificar si la distancia está dentro de la cobertura
    if (distance <= 30) {
      setCovertura(true)
      setInfo(false)
    } else {
      setCovertura(false)
    }
  }

  // Función para calcular la distancia entre dos puntos geográficos
  const getDistanceBetweenPoints = (latitude1, longitude1, latitude2, longitude2, unit = 'kilometers') => {
    let theta = longitude1 - longitude2;
    let distance = 60 * 1.1515 * (180 / Math.PI) * Math.acos(
      Math.sin(latitude1 * (Math.PI / 180)) * Math.sin(latitude2 * (Math.PI / 180)) +
      Math.cos(latitude1 * (Math.PI / 180)) * Math.cos(latitude2 * (Math.PI / 180)) * Math.cos(theta * (Math.PI / 180))
    );
    if (unit === 'miles') {
      return Math.round(distance, 2);
    } else if (unit === 'kilometers') {
      return Math.round(distance * 1.609344, 2);
    }
  }

  // Función para confirmar la cobertura según el código postal
  const Confirmar = async (CodigoPostal) => {
    setInfo(false);
    setShowMapa(false);
    try {
      // Llamar a la API para confirmar la cobertura
      const respuesta = await axios.get(
        Api + 'Establecimiento/2',
        {
          headers: {
            Authorization: Token,
          },
        }
      );
    } catch (error) {
      console.log("ERROR", error.response.status);
      // Manejar errores de la solicitud
      if (error.response.status === 500) {
        setErrorCovertura(true)
      }
    }
    // Cargar el mapa con el código postal proporcionado
    CargarMapa(CodigoPostal);
  }

  // Efecto secundario para cambiar el estado en el montaje inicial


   // Función para la navegación entre páginas de la aplicación
   const navigate = useNavigate();

   // Función para manejar el clic en los elementos del menú y redireccionar a las páginas correspondientes
   const handleClick = (link) => {
     navigate(link);
   };
   useEffect(() => {
    SwitchHandleChange('formCobertura');
}, []);

const handleChangeCP = (e) => {
  if (e.target.value.length < 6) {
    HandleChange(e);
  }
}


  // Renderizar el componente
  return (
    // Contenedor principal
    <>
      <div>
        <Typography variant='h1' fontSize='40px' mb='0px' fontWeight='600 !important' className="titulocobertura">Cobertura</Typography>
        <Typography sx={{ color: '#777777', fontSize: '15px', fontWeight: '400 !important' }}>
          Verifica la cobertura de nuestro servicio ingresando tu código postal
        </Typography>
        <div className="row mt-3">
          <div  className="col-lg-8">
            <div className="row">
              <div style={{ marginTop: '10px' }} className="col-sm-8 inputDatos">
                {/* Input para ingresar el código postal */}
                <StyleButton>
                  <input style={{ border: '1px solid #ced4da' }} type="number" min="0" max="99999" name='CodigoPostal' placeholder='Ingresa tu codigo postal' onChange={handleChangeCP} value={FormCobertura.CodigoPostal} maxLength="5" className="form-control" />
                </StyleButton>
                {/* Enlace para mostrar el modal de consulta del código postal */}
                <Link component='button' sx={{ fontSize: '12px', '&:hover': { color: '#FFEC00' }, fontWeight: '400 !important' }} color='#d63384' underline='none' id="btnDesconozco" onClick={() => { ShowModalConsultarCodigoPostal(); setCovertura(''); }}>
                  No conozco mi codigo postal
                </Link>
              </div>
              <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }} className="col-sm-4">
                {/* Botón para buscar la cobertura */}
                <StyleDragArea>
                  <button style={{ width: '200px', fontSize: '16px', color: '#484848', fontWeight: '500 !important' }} type="button" disabled={FormCobertura.CodigoPostal === '' ? true : false} onClick={() => (Confirmar(FormCobertura.CodigoPostal))} className="btn btnAmarilloChico">
                    Buscar
                  </button>
                </StyleDragArea>
              </div>
            </div>
            {/* Sección de información sobre los servicios */}
            {
              Info === true
                ?
                <div id="divInformativo" className="mt-3 informacion">
                  <h6 style={{ fontSize: '16px', color: '#848484' }}>Todos los servicios incluyen:</h6>
                  <ul style={{ listStyle: 'none', paddingLeft: '1rem' }} className='cobertura' type='A'>
                    <li>- Traslado 30 km redondos</li>
                    <li>- Trámites legales (acta de defunción, traslado, etc.)</li>
                    <li>- Preservación</li>
                    <li>- Conmemoración</li>
                  </ul>
                </div>
                :
                <></>
            }
            {/* Sección de cobertura */}
            <div style={{ marginTop: '50px' }} id="conCovertura">
              {
                Covertura === true
                  ?
                  <div className="row col-sm-12 mt-3" >
                    <p style={{ fontSize: '15px' }}>
                      El código postal ingresado <samp style={{ fontSize: '15px' }} className="subtitulo">SI se
                        encuentra</samp> dentro de nuestra zona de cobertura, has clic en
                      <samp className="subtitulo" style={{ fontSize: '15px' }}> SIGUIENTE </samp> para continuar con tu
                      solicitud.
                    </p>
                    <div className="col-sm-12" style={{ textAlign: 'right' }}>
                      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <StyleDragArea>
                          <button
                            style={{ fontSize: '16px', color: '#484848', marginBottom: '10px', width: '200px' }}
                            type="button"
                            onClick={()=>{OnClickPersonaliza(); handleClick('/SetsFunerarios/PersonalizaSet')}}
                            className="btn btnAmarilloChico"
                          >
                            Siguiente
                          </button>
                        </StyleDragArea>
                      </Box>
                    </div>
                  </div>
                  : <></>
              }
            </div>
            {/* Sección de no cobertura */}
            <div style={{ marginTop: '50px' }} id="sinCovertura">
              {
                Covertura === false
                  ?
                  <>
                    <div className="row col-sm-12">
                      <div>
                        <p style={{ fontSize: '15px', color: '#777777' }}>
                          El código postal ingresado
                          <spam className="subtitulo" style={{ fontSize: '15px' }}> NO se encuentra</spam> dentro de
                          nuestra zona de cobertura, comunícate con nosotros para más
                          información.
                        </p>
                      </div>
                    </div>
                    <div className="row col-sm-12" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                      <div onClick={() => (window.open('tel:'+CallNumber, "_blank"))} style={{cursor:'pointer', width: '200px', marginBottom: '1%', padding: '5px', borderRadius: '5px', backgroundColor: '#ffec00', fontSize: '22px' }}>
                        000-000-0000
                      </div>
                      
                      {/* Enlace para finalizar */}
                      <Link component='button' color='#d63384' onClick={OnClickClose} >Finalizar</Link>
                    </div>
                  </>
                  : <></>
              }
            </div>
            {/* Sección de error */}
            <div style={{ marginTop: '10%' }} id="divError">
              {
                ErrorCovertura === true
                  ?
                  <>
                    <div className="row col-md-12">
                      <div>
                        <p style={{ fontSize: '24px' }}> Error en el sistema, comunícate con nosotros para más información.</p>
                      </div>
                    </div>
                    <div className="row col-md-12" style={{ textAlign: 'center' }}>
                      <div>
                        {/* Enlace con número de teléfono */}
                        <Link component='button' underline='none' onClick={() => (window.open('tel:'+CallNumber, "_blank"))} className="btn btnAmarilloChico" id="telefono" >000-000-0000</Link><br />
                        {/* Enlace para finalizar */}
                        <Link component='button'  underline='none'>Finalizar</Link>
                      </div>
                    </div>
                  </>
                  : <></>
              }
            </div>
          </div>
          {/* Mapa */}
          <div className='col-lg-4' style={{ display: 'flex', justifyContent: 'center' }}>
            <div ref={refMapa} style={{margin:'0px', padding: '0px', borderRadius: '20px', width: '320px', height: '320px' }} className={Covertura === true ? "col-lg-4 mapacovertura" : "col-lg-4 mapa"}>
              {
                ShowMapa === true
                  ?

                  <GoogleMapReact
                    bootstrapURLKeys={{ key: apiKey }}
                    center={Mapa.lat === 0 ? defaultProps.center : Mapa}
                    zoom={11}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onGoogleApiLoaded={({ map, maps }) => new maps.Circle({
                      strokeColor: '#b0427e', strokeOpacity: 0.8, strokeWeight: 2, fillColor: '#b0427e', fillOpacity: 0.35, map, center: Mapa.lat === 0 ? defaultProps.center : Mapa, radius: 9000,
                    })}
                  >
                    <AnyReactComponent lat={59.955413} lng={30.337844} text="My Marker" />
                  </GoogleMapReact>



                  :
                  <></>
              }
            </div>
          </div>

        </div>
      </div>
      {/* Modal para consultar código postal */}
      {
        ModalConsultarCodigopostal === true
          ? <ModalBaseMui FullScreen={false} FullWidth={true}  MaxWidth='595px' Open={ModalConsultarCodigopostal} OnClickClose={ShowModalConsultarCodigoPostal}
            ContentModal={
              <Box sx={{ margin: '0px', padding:{xs:'0px 16px',sm:'0px 25px'}  }} className="modal-content">

                <Typography mb='16px' variant='h5' fontWeight='600 !important' fontSize='20px' textAlign='center' color='#d14081'>Consulta tu código postal</Typography>

                <div className="modal-body">
                  <div className="row" mt-2>
                    <div className="col-md-7">
                      <div className=" col-md-12 selectdiv ">
                        <select style={{ width: '100%' }} className="" id="inEstado" name='Estado' onChange={OnChange} >
                          <option value="0" defaultValue={true}>Selecciona</option>
                          {
                            DataEstado.map((element) => (
                              <option key={element.estadoId.trim()} value={element.estadoId.trim()} >{element.nombre.trim()}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div className="col-md-12 selectdiv mt-3">
                        <select className="" id="inMunicipio" name='Municipio' onChange={OnChange} >
                          <option value="0" defaultValue={true}>Selecciona</option>
                          {
                            DataMunicipio.length > 0
                              ? DataMunicipio.map((element) => (<option key={element.municipioId.trim()} value={element.municipioId.trim()} >{element.nombre.trim()}</option>))
                              : <></>
                          }
                        </select>
                      </div>
                      <div className="col-md-12 selectdiv mt-3">
                        <select className="" id="inColonia" name='Colonia' onChange={OnChange}>
                          <option value="0" defaultValue={true}>Selecciona</option>
                          {
                            DataColonia.length > 0
                              ? DataColonia.map((element) => (<option key={element.asentamientoId.trim()} value={element.asentamientoId.trim()} >{element.nombre.trim()}</option>))
                              : <></>
                          }
                        </select>
                      </div>
                    </div>

                    {
                      FormCobertura.Colonia !== ''
                        ?
                        <div className="col-md-5  ContenedorCodigoInformacion" >
                          <div id="divCodigo" className='conCodigo'>
                            <i id="iCodigo" style={{ display: 'flex', fontSize: '34px' }} className="bi bi-geo-alt">
                              <FmdGoodOutlinedIcon />
                              {
                                FormCobertura.CodigoPostal
                              }
                            </i>
                          </div>
                          <Box sx={{ fontSize: { xs: '12px', sm: '14px' } }} id="divInformacion">
                            {DataEstado.find((element) => (element.estadoId.trim() === FormCobertura.Estado)).nombre + ',' + DataMunicipio.find((element) => (element.municipioId.trim() === FormCobertura.Municipio)).nombre + ',' + FormCobertura.CodigoPostal + ',' + DataColonia.find((element) => (element.asentamientoId.trim() === FormCobertura.Colonia)).nombre}
                          </Box>

                        </div>

                        : <></>
                    }

                    <div className='col-md-12 mt-3' style={{ display: 'flex', justifyContent: 'center' }}>
                      <StyleDragArea>
                        <button hidden={FormCobertura.Colonia !== ''?false:true} onClick={ShowModalConsultarCodigoPostal} type="button" className="btn btnRosaChico">Confirmar</button>
                      </StyleDragArea>
                    </div>


                  </div>
                </div>
              </Box>
            }
          />
          : <></>
      }
    </>
  )
}

// Exportar el componente CoberturaSets
export default CoberturaSets;

